var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InfoAlert',{staticClass:"mb-5",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}}),_c('div',[_vm._v(" "+_vm._s(_vm.org_alert_text)+" "),(_vm.user_organization.manager_dpp_email)?_c('a',{class:_vm.$style.link,attrs:{"href":("mailto:" + (_vm.user_organization.manager_dpp_email))}},[_vm._v(_vm._s(_vm.user_organization.manager_dpp_email))]):_vm._e()])]},proxy:true}])}),_c('FiltersComp',{class:_vm.$style.filters,attrs:{"filters":_vm.filters,"cities":_vm.cities,"search_label":"Поиск по коду, ИНН, КПП"},on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"additional-filters",fn:function(){return [(_vm.is_not_desktop)?_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0 ml-auto'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1):_vm._e()]},proxy:true},(!_vm.is_not_desktop)?{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}:null],null,true)}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.filtered_cols,"items":_vm.filtered_organizations,"options":_vm.table_options,"footer-props":{
      itemsPerPageOptions: [10, 25, 50, 100],
    },"hide-default-footer":"","no-data-text":"Организации, удовлетворяющие выбранным параметрам, отсутствуют"},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
    var item = ref.item;
return [(_vm.get_item_code(item) || _vm.get_item_code(item) !== '-')?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.copy(_vm.get_item_code(item))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.get_item_code(item))+" ")])]}},{key:"item.name_organization",fn:function(ref){
    var item = ref.item;
return [(item.name_organization)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.name_organization)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.name_organization)+" ")])]}},{key:"item.organization_status.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.organization_status.name)+" ")])]}},{key:"item.organization_type.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.organization_type.name)+" ")])]}},{key:"item.active_contracts",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.format_active_contracts(item.active_contracts))+" ")])]}},{key:"item.inn_organization",fn:function(ref){
    var item = ref.item;
return [_c('div',{class:_vm.$style.contacts_min_width},[(item.inn_organization)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.inn_organization)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.inn_organization)+" ")])],1)]}},{key:"item.kpprganization",fn:function(ref){
    var item = ref.item;
return [_c('div',{class:_vm.$style.contacts_min_width},[(item.kpp_organization)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.kpp_organization)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.kpp_organization)+" ")])],1)]}},{key:"item.city",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.city)+" ")])]}},{key:"item.is_service_contract",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.translate_org_areement(item.is_service_contract))+" ")])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)}),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }